import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreateCart)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'cart-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Create cart'))+" ")]):_vm._e()]},proxy:true},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'single-order', params: { id: item.number } }}},[_vm._v(" SD-"+_vm._s(item.number)+" ")])]}},{key:"item.customer.firstname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer && item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.customer.customer_id } }}},[_vm._v(" "+_vm._s(item.customer.firstname)+" ")])]}},{key:"item.customer.lastname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer && item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.customer.customer_id } }}},[_vm._v(" "+_vm._s(item.customer.lastname)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-gap-8"},[_c('order-status-chip',{attrs:{"value":item.status}}),(item.customer.guest_id)?_c('order-status-chip',{attrs:{"value":"guest"}}):_vm._e()],1)]}},{key:"item.summary.total_due.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.summary.total_due}})]}},{key:"item.requested_delivery",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.requestedDelivery(item.requested_delivery)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'single-order', params: { id: item.number } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('order-action-delete',{attrs:{"value":item,"icon":"","disabled":_vm.grid.loading},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Orders') },false))}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"small":"","depressed":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.$t('Hold'))+" ")])]}}]),model:{value:(_vm.showHoldDialog),callback:function ($$v) {_vm.showHoldDialog=$$v},expression:"showHoldDialog"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.holdHandler.apply(null, arguments)}}},[(_vm.showHoldDialog)?_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Hold order'))+": "+_vm._s(_vm.value.number))]),_c(VIcon,{attrs:{"size":"32"},on:{"click":function($event){_vm.showHoldDialog = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('Reason'),"error-messages":_vm.errors['reason']},on:{"input":_vm.onItemChange},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end ma-2"},[_c(VBtn,{attrs:{"disabled":!_vm.isValid,"depressed":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Hold'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])]}}]),model:{value:(_vm.showCancelDialog),callback:function ($$v) {_vm.showCancelDialog=$$v},expression:"showCancelDialog"}},[(_vm.showCancelDialog)?_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Cancel order'))+": "+_vm._s(_vm.value.number))])]),_c(VDivider),_c(VCardText,{staticClass:"mt-4"},[_c('span',[_vm._v(_vm._s(_vm.$t('Are you sure you want to cancel order?')))])]),_c(VDivider),_c(VCardActions,{staticClass:"justify-space-between ma-2"},[_c(VBtn,{attrs:{"depressed":"","color":"secondary","text":""},on:{"click":function($event){_vm.showCancelDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")]),_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":_vm.cancelHandler}},[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }